$black: #333333
$white: #edecec
$blue: #037EB4
$yellow: #FAB914

body
    font-family: "Lato", serif
    font-weight: 400
    font-style: normal

h1
    text-transform: uppercase
    color: $white
    margin: 2rem 0

h2, h3, h4
    color: $blue
    font-weight: 600
    text-align: center

.wrap
    max-width: 800px
    margin: 0 auto
    text-align: center

.text-left
    text-align: left

.text-right
    text-align: right

.debug
    border: 1px solid red

.header-logo
    img
        width: 100%
        max-width: 200px

.navbar-menu
    background-color: white
    position: fixed
    top: 0
    left: 0
    right: 0
    z-index: 1000
    border-bottom: 1px solid lightgray

.btn-steinschlag
    background-color: $yellow
    color: $black
    padding: 10px 20px
    border-radius: 25px
    font-weight: 700
    &:hover
        background-color: $white

.header-nav
    ul
        list-style: none
        padding: 0
        margin: 0
        display: flex
        justify-content: space-evenly
        li
            margin: 0 1rem
        a
            text-decoration: none
            color: $black
            font-size: 1rem
            text-transform: uppercase
            transition: color 0.3s ease
            &:hover
                color: $blue
.header-contact
    span
        font-size: 0.8rem
    i
        color: $blue

.mobile-menu
    background-color: white
    position: fixed
    top: 70px
    left: 0
    right: 0
    z-index: 1000
    transform: translateY(-100%)
    opacity: 0
    visibility: hidden
    transition: all 0.3s ease-in-out

    &.is-active
        transform: translateY(0)
        opacity: 1
        visibility: visible

    ul
        list-style: none
        padding: 1rem 0
        margin: 0
        text-align: center
        li
            margin: 1rem
            a
                text-decoration: none
                color: $blue
                font-size: 1.3rem
                text-transform: uppercase
                transition: color 0.3s ease

.hero-title
    position: relative
    background-image: url('/images/hero-photo.png')
    background-size: cover
    background-position: center
    min-height: 70vh
    &::before
        content: ''
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background: linear-gradient(90deg,
            rgba(0,0,0,0.8) 0%,
            rgba(0,0,0,0.5) 45%,
            rgba(0,0,0,0) 80%
        )
        z-index: 1
    > *
        position: relative
        z-index: 2
    p
        color: $white

.toll-slider
    position: absolute
    right: 0
    transform: translateY(-200%)
    z-index: 2
    .wrapper
        background-color: $white
        border-radius: 25px 0 0 25px
        span
            color: $blue
            font-size: 1.3rem
        span:last-child
            font-weight: 600

.toll-sticker
    background-size: cover
    background-position: center
    min-height: 50vh
    position: relative
    overflow: hidden
    background-image: url('/images/toll-bg.jpg')
    background-attachment: fixed
    .toll-decoration
        transform: rotate(15deg)

.services
    span.title
        display: block
        font-size: 1.3rem
        font-weight: 600
        text-transform: uppercase
        color: $blue
        text-align: center
        margin: 1rem 0

.reparis
    background-size: cover
    background-position: center
    min-height: 50vh
    position: relative
    overflow: hidden
    background-image: url('/images/reparaturen-bg.jpg')
    background-attachment: fixed

.list-steinschlag
    list-style: none
    li
        margin-bottom: .5rem
        &:before
            content: ''
            display: inline-block
            width: 13px
            height: 13px
            background-color: $blue
            border-radius: 3px
            margin-right: .5rem

.bg-black
    background-color: $black
    padding: 2rem 0

.tint-benefits
    background-image: url('/images/benefits.jpg')
    background-size: cover
    background-position: center
    position: relative
    overflow: hidden

.contact
    i
        color: $blue
    form
        input, textarea
            border: none
            background-color: $white
            border-radius: 15px
            padding: 10px 20px
            margin-bottom: 10px
            width: 100%
            resize: none
        button
            background-color: $yellow
            border: none
            color: $black
            padding: 10px 20px
            border-radius: 25px
            font-weight: 700
            &:hover
                background-color: $white

footer
    background-color: $black

// Hamburger menu customization
$hamburger-padding-x: 5px
$hamburger-padding-y: 5px
$hamburger-layer-width: 32px
$hamburger-layer-height: 3px
$hamburger-layer-spacing: 6px
$hamburger-layer-color: $black
$hamburger-hover-opacity: 0.7

@import "hamburgers/_sass/hamburgers/hamburgers"

.hamburger
    float: right
    padding: $hamburger-padding-y $hamburger-padding-x
    display: inline-block
    cursor: pointer
    transition-property: opacity, filter
    transition-duration: 0.15s
    transition-timing-function: linear
    font: inherit
    color: inherit
    text-transform: none
    background-color: transparent
    border: 0
    margin: 0
    overflow: visible

    &:hover
        opacity: $hamburger-hover-opacity

    &.is-active
        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after
            background-color: $hamburger-layer-color

.hamburger-box
    width: $hamburger-layer-width
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2
    display: inline-block
    position: relative

.hamburger-inner
    display: block
    top: 50%
    margin-top: $hamburger-layer-height / -2

    &,
    &::before,
    &::after
        width: $hamburger-layer-width
        height: $hamburger-layer-height
        background-color: $hamburger-layer-color
        border-radius: 4px
        position: absolute
        transition-property: transform
        transition-duration: 0.15s
        transition-timing-function: ease

    &::before,
    &::after
        content: ""
        display: block

    &::before
        top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1

    &::after
        bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1

// Spin animation
.hamburger--spin
    .hamburger-inner
        transition-duration: 0.22s
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)

        &::before
            transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in

        &::after
            transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)

    &.is-active
        .hamburger-inner
            transform: rotate(225deg)
            transition-delay: 0.12s
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)

            &::before
                top: 0
                opacity: 0
                transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out

            &::after
                bottom: 0
                transform: rotate(-90deg)
                transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)

@media screen and (max-width: 768px)
    @import "responsive-md"