// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font Awesome
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/brands";

// AOS
@import 'aos/dist/aos.css';

// Your custom SCSS/SASS styles below
@import 'resources/sass/frontend.sass';